import {isProduction} from "@/utils/urlHelper";

export const FunctionIds = {
  AppAdmin: 101745360,
  Extern: isProduction() ? 122133857 : 122133857
}

export const FunctionTypeNames = {
  AppAdmin: "AppAdmin",
  Member: "Vereinsmitglied"
}
