import {createMongoAbility, CreateAbility, MongoAbility, AbilityBuilder, InferSubjects} from '@casl/ability';
import {Session, User} from "next-auth";
import * as process from "process";
import {Club, Team} from "@/sources/kicker-amateur/types";
import {NewsContext} from "@vereinsheim/backend-types/src/news";
import {FunctionIds, FunctionTypeNames} from '@/sources/kicker-amateur/constants';

interface AbilityFunction {
  id?: string;
  teamId?: string;
  clubId?: string;
}

interface AbilityPerson {
  id: string;
  teamId?: string;
  clubId?: string;
}

interface AbilityTeam {
  id: string;
}

interface AbilityClub {
  id: string;
}

interface AbilityTeamTab {
  teamId: string;
}

interface AbilityTransfer {
  teamId: string;
}

interface AbilityNews {
  contextId: string;
  newsContext: NewsContext
}

interface AbilityPoll {
  clubId?: string;
  teamId?: string;
}

interface AbilityPollResponse {
  clubId?: string;
  teamId?: string;
}

interface AbilityEvent {
  clubId?: string;
  teamId?: string;
}

interface AbilityPersonEvent {
  clubId: string;
}

interface AbilityEventResponse {
  clubId?: string;
  teamId?: string;
}

interface AbilityInvitation {
  // kind: "Invitation";
  contextId: string;
  contextType: "Team" | "Club"
}

interface AbilityUserPersonLink {
  clubId: string;
}

interface AbilityUserInvite {
  clubId?: string;
  teamId?: string;
}

interface AbilityTeamWidget {
  teamId?: string;
}

interface AbilityTicker {
  teamId: string;
}
interface AbilityGrafikgenerator {
  clubId: string;
}

export type Action = 'manage' | 'create' | 'update' | 'list' | 'list-all' | 'delete' | 'new' | 'show' | 'search';
// type Subject = Club | 'Club' | 'Invitation' | InvitationCreateRequest | 'Person' | 'Function' | AbilityFunction | 'Team' | 'all';
// type Subject = InferSubjects<AbilityFunction | AbilityPerson | AbilityTeam | AbilityClub | AbilityInvitation, true> | 'all';
export type Subject = AbilityGrafikgenerator | 'Grafikgenerator' | AbilityTicker | 'Ticker' | AbilityNews | "News" | AbilityFunction | "Function" | AbilityTeam | "Team" | AbilityClub | "Club" | AbilityPerson | "Person" | AbilityInvitation | "Invitation" | AbilityTeamTab | "TeamTab" | AbilityTransfer | "Transfer" | AbilityTeamWidget | "TeamWidget" | AbilityPoll | "Poll" | AbilityPollResponse | "PollResponse" | AbilityEvent | "Event" | AbilityPersonEvent | "PersonEvent" | AbilityEventResponse | "EventResponse" | AbilityUserPersonLink | "UserPersonLink" | AbilityUserInvite | "UserInvite" | "Notifications" | "all" ;

type AppAbilities = [
  Action, Subject
];

export type AppAbility = MongoAbility<AppAbilities>;
export const createAppAbility = createMongoAbility as CreateAbility<AppAbility>;

export function defineAbilityFor(session: Session | null, pwaId: number | null, teams: Team[] | null): AppAbility {
  const builder = new AbilityBuilder(createAppAbility);

  let isClubMember = false
  let isClubPlayer = false
  let isClubCoach = false
  let isClubAppAdmin = false

  if(pwaId && session?.user){
    builder.can('list', 'Notifications');
    session?.user?.personFunctions?.forEach((func) => {
      if(func.functionTypeName == FunctionTypeNames.Member) {
        isClubMember = true;
      }

      if(func.functionGroupType === "Players" && teams && teams.find((team) => team.Id?.toString() === func.organizationId?.toString())) {
        isClubPlayer = true;
        // PLAYERS
        builder.can('list', 'Event', {clubId: pwaId.toString()});
        builder.can('show', 'Event', {clubId: pwaId.toString()});
        builder.can('list', 'Person', {clubId: pwaId.toString()});
        builder.can('show', 'Person', {clubId: pwaId.toString()});
        builder.can('list', 'Poll', {clubId: pwaId.toString()});
        builder.can('show', 'Poll', {clubId: pwaId.toString()});
        builder.can('show', 'Poll', {teamId: func.organizationId?.toString()});
        builder.can('list', 'Poll', {teamId: func.organizationId?.toString()});
        builder.can('manage', 'PollResponse', {teamId: func.organizationId?.toString()})
        builder.can('manage', 'PollResponse', {clubId: pwaId.toString()})
        builder.can('manage', 'EventResponse', {teamId: func.organizationId?.toString()})
        builder.can('manage', 'EventResponse', {clubId: pwaId.toString()});
        // allow player to do something for !EVERY! team
        (teams || []).forEach((team: Team) => {
          builder.can('list', 'Event', {teamId: team.Id?.toString()})
          builder.can('show', 'Event', {teamId: team.Id?.toString()})
        })
      }

      if(func.functionGroupType === 'Coaches' && teams && teams.find((team) => team.Id?.toString() === func.organizationId?.toString())) {
        isClubCoach = true;
        // COACHES
        builder.can('search', 'Team');
        builder.can('manage', 'Team', {id: func.organizationId?.toString()});
        // builder.can('manage', 'Person', {teamId: func.organizationId?.toString()}); //TODO:Matthias check => only admins
        builder.can('manage', 'News', {contextId: func.organizationId?.toString(), newsContext:"Team"});
        builder.can('list', 'Person', {clubId: pwaId.toString()});
        builder.can('show', 'Person', {clubId: pwaId.toString()});
        builder.can('show', 'Person', {teamId: func.organizationId?.toString()});
        builder.can('create', 'Person', {teamId: func.organizationId?.toString()});
        builder.can('manage', 'Function', {teamId: func.organizationId?.toString()});
        builder.can('create', 'Invitation', {contextId: func.organizationId?.toString(), contextType: "Team"});
        builder.can('manage', 'Event', {teamId: func.organizationId?.toString()});
        builder.can('list', 'Event', {clubId: pwaId.toString()});
        builder.can('list', 'Poll', {clubId: pwaId.toString()});
        builder.can('list', 'Poll', {teamId: func.organizationId?.toString()});
        builder.can('show', 'Poll', {teamId: func.organizationId?.toString()});
        builder.can('manage', 'Event', {teamId: func.organizationId?.toString()});
        builder.can('manage', 'Poll', {teamId: func.organizationId?.toString()});
        builder.can('manage', 'PollResponse', {teamId:func.organizationId?.toString()});
        builder.can('manage', 'PollResponse', {clubId:pwaId.toString()});
        builder.can('manage', 'EventResponse', {teamId:func.organizationId?.toString()});
        builder.can('manage', 'EventResponse', {clubId:pwaId.toString()});
        builder.can('show', 'Event', {clubId: pwaId.toString()});
        builder.can('list', 'TeamWidget', {teamId: func.organizationId?.toString()});
        builder.can('create', 'Ticker', {teamId: func.organizationId?.toString()});
        // allow coach to do something for !EVERY! team
        (teams || []).forEach((team: Team) => {
          builder.can('list', 'Event', {teamId: team.Id?.toString()});
          builder.can('show', 'Event', {teamId: team.Id?.toString()})
          builder.can('manage', 'UserInvite', {teamId: team.Id?.toString()})
        })
        builder.can('create', "Event", {clubId: pwaId.toString()})
      }

      if(func.functionTypeName === FunctionTypeNames.AppAdmin && pwaId.toString() === func.organizationId?.toString()) {
        isClubAppAdmin = true;
        if(teams) {
          teams.forEach((team) => {
            // ADMIN (team)
            builder.can('search', 'Team');
            builder.can('manage', 'Team', {id: team.Id?.toString()});
            builder.can('manage', 'Function', {teamId: team.Id?.toString()});
            builder.can('manage', 'Person', {teamId: team.Id?.toString()});
            builder.can('manage', 'Transfer', {teamId: team.Id?.toString()});
            builder.can('manage', 'TeamTab', {teamId: team.Id?.toString()});
            builder.can('manage', 'Invitation', {contextId: team.Id?.toString(), contextType: "Team"});
            builder.can('manage', 'News', {contextId: team.Id?.toString(), newsContext:"Team"});
            builder.can('manage', 'Event', {teamId: team.Id?.toString()});
            builder.can('manage', 'Poll', {teamId: team.Id?.toString()});
            builder.can('manage', 'PollResponse', {teamId:func.organizationId?.toString()})
            builder.can('show', 'EventResponse', {teamId:func.organizationId?.toString()})
            builder.can('create', 'Event', {teamId: team.Id?.toString()});
            builder.can('list', 'TeamWidget', {teamId: team.Id?.toString()});
            builder.can('create', 'Ticker', {teamId: func.organizationId?.toString()})
            builder.can('manage', 'UserInvite', {teamId: team.Id?.toString()})
          });
        }
        // ADMIN (general)
        builder.can('search', 'Person');
        builder.can('manage', 'Function', {clubId: pwaId.toString()});
        builder.can('manage', 'Person', {clubId: func.organizationId?.toString()});
        builder.can('manage', 'Club', {id: func.organizationId?.toString()});
        builder.can('manage', 'Invitation', {contextId: func.organizationId?.toString(), contextType: "Club"});
        builder.can('manage', 'News', {contextId: func.organizationId?.toString(), newsContext:"Club" });
        builder.can('manage', 'Event', {clubId: func.organizationId?.toString()});
        builder.can('update', 'Event', {clubId: func.organizationId?.toString()});
        builder.can('manage', 'Poll', {clubId: func.organizationId?.toString()});
        builder.can('manage', 'PollResponse', {clubId: pwaId.toString()})
        builder.can('manage', 'UserPersonLink', {clubId: pwaId.toString()});
        builder.can('manage', 'EventResponse', {clubId:pwaId.toString()});
        builder.can('create', "Event", {clubId: pwaId.toString()})
        builder.can('update', "PersonEvent", {clubId: pwaId.toString()})
        builder.can('manage', 'UserInvite', {clubId: pwaId.toString()})
      }
    });

    if(isClubPlayer || isClubCoach || isClubAppAdmin || isClubMember) {
      //other members
      builder.can('create', "PersonEvent", {clubId: pwaId.toString()})
      builder.can('new', "Event", {clubId: pwaId.toString()})
      builder.can('list', 'Event', {clubId: pwaId.toString()});
      builder.can('show', 'Grafikgenerator', {clubId: pwaId.toString()});
    }
  }

  return builder.build();
}


