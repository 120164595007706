'use client';

import React from 'react'
import { AppAbility, defineAbilityFor } from '@/app/pwa/lib/app-ability';
import { useSession } from 'next-auth/react';
import { createContextualCan } from '@casl/react';
import {Team} from "@/sources/kicker-amateur/types";

const AbilityContext = React.createContext<AppAbility>(defineAbilityFor(null, null, null));

export default function AbilityProvider({children, pwaId, teams}: {children: React.ReactNode, pwaId: number, teams: Team[]}) {
  const { data: session } = useSession();
  const ability = React.useMemo(() => {
    return defineAbilityFor(session, pwaId, teams);
  }, [session]);

  return <AbilityContext.Provider value={ability}>
    {children}
  </AbilityContext.Provider>
}

export function useAbility() {
  const ability = React.useContext(AbilityContext);
  if (!ability) {
    throw new Error('useAbility can only be used within a AbilityProvider');
  }
  return ability;
}

export const Can = createContextualCan(AbilityContext.Consumer);
